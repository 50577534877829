import ApiService from '@/shared/services/api.service';

// action types
export const GET_APPLICATION_STATUS = 'getApplicationStatus';

export const SET_APPLICATION_STATUS = 'setApplicationStatus';

const state = {
    application: null
};

const getters = {
    application(state) {
        return state.application;
    },
};

const actions = {
    [GET_APPLICATION_STATUS](context, id) {
        context.commit('shared/activateLoading', 'application-status/getApplicationStatus', { root: true });
        return new Promise((resolve, reject) => {
            ApiService.get(`application-public/${id}`)
                .then(({ data }) => {
                    context.commit(SET_APPLICATION_STATUS, data);
                    resolve(data);
                })
                .catch((error) => {
                    context.commit(SET_APPLICATION_STATUS, { success: false, withError: true });
                    reject(error);
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'application-status/getApplicationStatus', { root: true });
                })
        });
    },
};

const mutations = {
    [SET_APPLICATION_STATUS](state, application) {
        state.application = application;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
